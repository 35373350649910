.form-add {
  display: inline-block;
  position: relative;
  cursor: pointer;
  vertical-align: middle;
}

input[type=checkbox] {
  &.input-switch {
    display: none;

    & + .form-add {
      @include border-radius(12px);
      @include transition(all .3s ease);

      width: 53px;
      height: 22px;
      margin: 0 5px;
      background-color: $input-border-color;

      &:after {
        @include border-radius(50%);
        @include transition(left .3s ease);

        font-family: Arial, Helvetica, sans-serif;
        font-size: 8px;
        line-height: 16px;
        position: absolute;
        top: 2px;
        left: 2px;
        width: 18px;
        height: 18px;
        content: '|||';
        text-align: center;
        color: darken(theme-color('primary'), 20%);
        background-color: #fff;
      }
    }

    &:checked + .form-add {
      background-color: theme-color('primary');

      &:after {
        left: 33px;
      }
    }
  }

  &.input-check {
    display: none;

    & + .form-add {
      width: 16px;
      height: 16px;
      margin: 0 5px 5px;
      border: 1px solid $input-border-color;
      background: #fff;

      &:after {
        @include transition(all .2s ease);

        position: absolute;
        top: 2px;
        left: 2px;
        width: 10px;
        height: 10px;
        content: ' ';
        background-color: #fff;
      }

      &:hover {
        border-color: theme-color('primary');
      }
    }

    &:checked + .form-add {
      &:after {
        background-color: theme-color('primary');
      }
    }
  }
}

input[type=radio] {
  &.input-radio {
    display: none;

    & + .form-add {
      @include border-radius(50%);

      width: 16px;
      height: 16px;
      margin: 0 5px 5px;
      border: 1px solid $input-border-color;
      background: #fff;

      &:after {
        @include border-radius(50%);
        @include transition(all .2s ease);

        position: absolute;
        top: 2px;
        left: 2px;
        width: 10px;
        height: 10px;
        content: ' ';
        background-color: #fff;
      }

      &:hover {
        border-color: theme-color('primary');
      }
    }

    &:checked + .form-add {
      &:after {
        background-color: theme-color('primary');
      }
    }
  }
}

.form-group {
  .radio,
  .checkbox {
    label .form-add {
      margin-left: -20px;
    }
  }
}

.footer {
  padding: 10px 0;
  color: $default-footer-font-color;
  background: $default-footer-background;

  a {
    color: $default-footer-link-color;

    &:hover {
      color: $default-footer-link-color-hover;
    }
  }
}

/*
//Include to add Sprites to your CSS File
@import "sprites/sprite-...";
@include sprites($spritesheet-sprites);
//@include retina-sprites($retina-groups); //Include if "enable_retina_sprites" is true
*/

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;

  @include media-breakpoint-down(md) {
    padding-bottom: 65px;
  }
}

.navbar {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}

.primary-box {
  background: $brand-primary;
  padding: 20px 0;
  margin: 30px 0;

  .primary-box-item {
    display: block;
    cursor: pointer;
    max-width: 270px;
    margin: 30px auto;
    background: #fff;
    padding: 15px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.25);
    transition: all 0.2s ease-in-out;
    text-align: center;
    text-decoration: none !important;
    height: calc(100% - 60px);

    &:focus {
      outline: 0;
    }

    .person-name {
      font-size: 1.2rem;
      font-weight: bold;
      margin: 15px 0 10px;
    }

    &:hover {
      transform: scale(1.03);
    }

    @include media-breakpoint-down(sm) {
      max-width: none;
      text-align: left;
      margin: 15px auto;
      height: calc(100% - 30px);

      .person-name {
        font-size: 1.1rem;
        margin-top: 0;
      }

    }
  }
}

.contact-info {
  .person-name {
    text-align: center;
    font-size: 1.7rem;
    font-weight: bold;
    margin: 0;
  }

  .person-position {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
    margin: 0 0 25px;
  }

  .person-phone,
  .person-email {
    text-align: center;
    margin: 10px 0;
  }
}

.btn-secondary {
  @include button-variant($brand-secondary, $brand-secondary);
  font-weight: bold;
  font-size: 1.1rem;
  min-width: 200px;
  padding-left: 25px;
  padding-right: 25px;
}

.site-content,
.site-footer {
  a {
    color: $body-color;
    text-decoration: underline;

    &:hover {
      color: #000;
    }
  }

  .btn {
    text-decoration: none;
  }
}

.site-header {
  height: 65px;
}

.navbar-toggler:focus {
  outline: none;
}

@include media-breakpoint-up(lg) {
  .navbar-expand-xl .navbar-nav {
    .nav-link {
      height: 65px;
      color: #333;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        right: 50%;
        transition: all 0.2s ease-in-out;
        height: 4px;
        background: $brand-primary;
      }
    }

    .nav-link:hover,
    .nav-item.show > .nav-link,
    .active > .nav-link {
      color: $brand-primary;

      &:before {
        left: 30px;
        right: 30px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .navbar-expand-xl .navbar-nav {
    .nav-link {
      color: #333;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: -1rem;
        top: 50%;
        bottom: 50%;
        transition: all 0.2s ease-in-out;
        width: 4px;
        background: $brand-primary;
      }
    }
    

    .nav-link:hover,
    .nav-item.show > .nav-link,
    .active > .nav-link {
      color: $brand-primary;

      &:before {
        top: .5rem;
        bottom: .5rem;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .navbar-expand-xl .navbar-nav .nav-link {
    padding: 22px 30px;
    @media(max-width:1470px){
      padding: 22px 8px;
    }
  }
  .navbar-collapse {
    flex-basis: auto;
  }
}

.dropdown {
  .up-arrow,
  .down-arrow {
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
    margin-left: 1px;
  }

  .up-arrow {
    display: none;
  }

  &.show {
    .up-arrow {
      display: inline-block;
    }

    .down-arrow {
      display: none;
    }
  }
}

.navbar-nav > .nav-item {
  &  > .nav-link {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 700;
    white-space: nowrap;

    &:after {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    .dropdown-menu {
      padding: 20px 0;
      margin: -4px 0 0;
      background: $brand-primary;
      min-width: 100%;
      border: 0;
      box-shadow: 0 10px 15px rgba(0, 0, 0, 0.25);
    }

    .dropdown-item {
      color: #fff;

      &:focus,
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }

      &.active,
      &:active {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }

  @include media-breakpoint-down(md) {
    .dropdown-menu {
      border: 0;
    }

    .dropdown-item {
      &:hover {
        background: transparent;
        text-decoration: underline;
      }

      &.active,
      &:active {
        color: $body-color;
        background: transparent;
        text-decoration: underline;
      }
    }
  }
}

.navbar-brand {
  display: block;
  background: $brand-primary;
  padding: 10px 30px;

  @include media-breakpoint-down(md) {
    padding: 10px;
  }
}

.nav-icons {
  background: $brand-secondary;
  padding: 0 10px;
}

.mobile-nav-icons {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  .nav-icon {
    flex: 1;
  }
}

.nav-icon {
  display: block;
  height: 65px;
  width: 40px;
  text-align: center;
  padding: 20px 0;

  &:hover {
    background: rgba(0, 0, 0, 0.06);
  }
}

@include media-breakpoint-up(lg) {
  .site-footer {
    font-size: 1rem;
  }
}

.form-control {
  &::placeholder {
    color: #999;
    font-weight: $font-weight-normal;
  }
}

.site-footer {
  h2 {
    font-weight: 700;
  }
}

.footer-logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto 40px;
  background: $brand-primary;
  text-align: center;

  img {
    width: 90px;
    height: auto;
    margin-top: 18px;
  }

  @include media-breakpoint-down(md) {
    width: 60px;
    height: 60px;

    img {
      width: 50px;
      margin-top: 13px;
    }
  }
}

.footer-address {
  line-height: 1.8rem;
}

.footer-nav {
  li {
    display: inline-block;

    a {
      display: inline-block;
      padding: 3px 10px 3px 0;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      color: $body-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &:first-child a {
      padding-left: 0;
    }

    &:last-child a {
      padding-right: 0;
    }

    @include media-breakpoint-down(md) {
      a {
        padding: 10px;
      }
    }
  }
}

.slide-img {
  height: 500px;
  background-position: center center;
  background-size: cover;

  @include media-breakpoint-down(lg) {
    height: 400px;
  }

  @include media-breakpoint-down(md) {
    height: 250px;
  }
}

.slide-title {
  margin-top: 20px;
  font-size: 2.4rem;
  font-weight: $font-weight-bold;
}

.slide-sub-title {
  margin-bottom: 20px;
  font-size: 1.4rem;
}

@include media-breakpoint-down(md) {
  .slide-title {
    font-size: 1.8rem;
  }

  .slide-sub-title {
    font-size: 1.2rem;
  }
}

.slick-dots li {
  margin: 0 2px;

  button:before {
    font-size: 15px;
    color: $brand-primary;
    opacity: 1;
  }
  &.slick-active button:before {
    font-size: 20px;
    color: $brand-secondary;
  }
}

.slick-dotted.slick-slider {
  padding-bottom: 60px;
  margin-bottom: 0;
}

.slick-dots {
  bottom: 40px;
}

.slick-slide {
  &:focus {
    outline: 0;
  }
}

.cols2-col {
  position: relative;
  padding-bottom: 90px;

  .btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.product-overview-img {
  width: 100%;
  padding-bottom: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.news-container {
  background: $brand-primary;
  padding: 40px 0;

  .news-container-title,
  .news-container-sub-title {
    color: #fff;
    text-align: center;
    font-size: 3rem;
  }

  .news-container-sub-title {
    font-weight: $font-weight-normal;
    font-size: 1.2rem;
  }
}

.news-block {
  display: block;
  margin: 30px 10px;
  background: #fff;
  padding: 15px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease-in-out;
  text-align: center;
  text-decoration: none !important;

  .row {
    align-items: center;
  }

  .btn-holder {
    margin-top: 30px;
    //display: none;
  }

  @include media-breakpoint-down(md) {
    .btn-holder {
      display: block;
    }
  }

  &:hover {
    transform: scale(1.03);

    .btn-holder {
      display: block;
    }
  }

  &.highlight {
    background: $brand-secondary;

    .btn-secondary {
      border-color: #fff;
      background: #fff;

      &:hover {
        background: #f9f9f9;
      }
    }
  }

  .title {
    font-size: 1.5em;
    font-weight: $font-weight-bold;
  }

  .date {
    margin: 0 0 20px;
    font-size: 0.9em;
  }

  .text {
    margin-bottom: 0.7rem;
  }
}

.content-title {
  font-size: 2.2rem;
  font-weight: 600;
}

.content-sub-title {
  font-size: 1.4rem;
  margin-bottom: 30px;
}

.wysiwyg {
  ul {
    list-style: none;
    padding-left: 17px;

    li {
      position: relative;
      margin-bottom: $default-margin / 2;

      &:before {
        content: '';
        position: absolute;
        top: 0.75em;
        left: -17px;
        width: 8px;
        border-top: 1px solid $body-color;
      }
    }
  }
}

.main-slider,
.image-slider {
  & > * {
    display: none;
  }
  & > *:first-child {
    display: block;
  }
  &.slick-initialized > * {
    display: block;
  }
}

.image-slider {
  display: block;
  max-width: 850px;
  margin: 30px auto !important;
  background: #fff;
  padding: 15px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.25);

  .slick-dots {
    bottom: 20px;
  }
}

.product-title {
  font-size: 2.7rem;
  font-weight: bold;
}
.product-sub-title {
  font-size: 1.3rem;
}

.primary-box .product-table {
  color: #fff;
}

.product-table {
  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    th,
    td {
      padding: 0 5px;
    }

    th {
      padding-bottom: 5px;
      font-weight: $font-weight-bold;
      font-size: 1.2em;
      text-align: left;
    }

    @include media-breakpoint-down(sm) {
      th {
        padding-bottom: 0;
        color: rgba(255, 255, 255, 0.6);
        font-weight: $font-weight-normal;
        font-size: 1rem;
      }
    }

    .seperate-table {
      padding-bottom: 30px;
    }

    tr:last-child .seperate-table {
      padding-bottom: 0;
    }
  }

  .product-table-title {
    font-weight: $font-weight-bold;
    font-size: 1.2em;
  }
}

.product-categories {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 15px 20px;

    a {
      text-decoration: none;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 1.1rem;
    }

    &.active a {
      color: $brand-primary;
    }
  }
}

.product-categories-mobile {
  position: relative;

  select {
    font-weight: bold;
    background: $brand-secondary;
    border: 0;
    border-radius: 0;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    padding-right: 25px;

    &:focus {
      background: $brand-secondary;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -5px;
    width: 17px;
    height: 10px;
    background: url('../img/icon-arrow-down.svg') no-repeat;
    pointer-events: none;
  }
}

.collapse-close {
  position: absolute;
  right: 5px;
  top: 5px;
  border: 0;
  background: transparent;
  padding: 10px 15px;

  &:focus {
    outline: 0;
  }

  &:hover {
    opacity: 0.8;
  }
}

.collapse-info {
  background: #fff;
  padding: 60px 0;
  margin: 30px 0 15px;
  position: relative;

  &:before {
    @include caret-pando('top', 30px);
    position: absolute;
    bottom: 100%;
  }

  .pos-1 &:before {
    left: calc(25% - 30px);
  }

  .pos-2 &:before {
    left: calc(75% - 30px);
  }

  .pos-contact-1 &:before {
    left: calc(50% - 30px);
  }

  .pos-md-1 &:before {
    left: calc(12.5% - 30px);
  }

  .pos-md-2 &:before {
    left: calc(12.5% + 25% - 30px);
  }

  .pos-md-3 &:before {
    left: calc(12.5% + 50% - 30px);
  }

  .pos-md-4 &:before {
    left: calc(12.5% + 75% - 30px);
  }
}

.product-info-title {
  font-weight: bold;
  font-size: 2.3rem;
}

.product-info-sub-title {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.header-image {
  height: 500px;
  background-position: center center;
  background-size: cover;

  @include media-breakpoint-down(lg) {
    height: 400px;
  }

  @include media-breakpoint-down(md) {
    height: 250px;
  }
}

.embed-responsive.youtube-video-link {
  cursor: pointer;
  position: relative;

  .preview-img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-position: center center;
    background-size: cover;
  }

  .play-btn {
    display: block;
    width: 120px;
    height: auto;
    margin-left: -60px;
    margin-top: -35px;
    position: absolute;
    top: 50%;
    left: 50%;
  }
}


.primary-box .download-box {
  position: relative;
  padding-bottom: 30px;

  .download-box-inner {
    height: 100%;

    &  > div {
      width: 100%;
    }
  }
  .download-preview-image {
    border:1px solid #ddd;
    margin:$default-margin auto;
    width:105px;
    height:auto;
  }

  .download-icon {
    margin-bottom: 10px;
  }
  .download-title {
    font-size: 1.2rem;
    font-weight: $font-weight-bold;
    margin-bottom: 10px;
  }
  .download-text {

  }
  .download-btn {
    display: none;
    position: absolute;
    bottom: -21px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  @include media-breakpoint-down(md) {
    margin: 20px auto;
    height: calc(100% - 40px);

    .download-btn {
      display: inline-block;
    }
  }

  &:hover {
    .download-btn {
      display: inline-block;
    }
  }
}

.shop-block {
  margin: 50px 0;

  .shop-name {
    font-size: 1.2rem;
    font-weight: $font-weight-bold;
  }
}

.form-message {
  display: none;
}

.product-page-logo {
  @include media-breakpoint-down(md) {
    max-height: 200px;
    width: auto;
  }
}

.copyright {
  a {
    color: $text-muted;
  }
}

.iframe-container{
  margin-bottom:$default-margin*2;
  iframe{
    width:100%;
    border: none;
  }
}
